<template>

    <div class="auth-wrapper auth-v1"
         style="padding-bottom:0px;padding-top:0px;">
        <div class="auth-inner main-container-width">
            <v-row>

                <v-col cols="12"
                       sm="8"
                       md="6"
                       lg="12"
                       class="mx-auto">

                    <v-card flat
                            class="bg-gradient">
                        <app-header></app-header>

                        <v-row class="pa-3">
                            <v-col lg="8">
                                <v-row class="mx-3 mt-3"
                                       justify="space-between">
                                    <v-col lg="6">
                                        <h2 style="font-size: 21px;">Add Your trips</h2>
                                    </v-col>

                                </v-row>
                                <v-row class="mx-3 mt-3"
                                       justify="space-between">
                                    <v-col lg="6">
                                        <h2 style="font-size: 12px;">Let us know your destination country, Arrival and
                                            leave date</h2>
                                    </v-col>

                                </v-row>
                                <div class="mx-5 mt-4 container">

                                    <v-row>
                                        <v-col lg="4">
                                            <div class="text-subtitle-1 text-medium-emphasis">Select Country</div>
                                            <v-select :items="items"
                                                      filled
                                                      outlined
                                                      
                                                      label="Country"></v-select>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <!-- prepend-icon="mdi-calendar" -->
                                        <v-col lg="3">
                                            <v-menu v-model="menu2"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field v-model="date"
                                                                  label="Arrival Date"
                                                                  outlined
                                                                  readonly
                                                                  v-bind="attrs"
                                                                  v-on="on"></v-text-field>
                                                </template>
                                                <v-date-picker v-model="date"
                                                               @input="menu2 = false"></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col lg="3">
                                            <v-menu v-model="menu3"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field v-model="date1"
                                                                  label="Leave Date"
                                                                  outlined
                                                                  readonly
                                                                  v-bind="attrs"
                                                                  v-on="on"></v-text-field>
                                                </template>
                                                <v-date-picker v-model="date1"
                                                               @input="menu3 = false"></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>


                                    <v-row>
                                        <v-col lg="4">
                                            <v-btn block
                                                   color="primary"
                                                   type="submit"
                                                   large>
                                                {{ $t('Next') }}
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                        </v-row>



                    </v-card>
                </v-col>
            </v-row>

        </div>

    </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline


import AppHeader from '../test/AppHeader.vue'


export default {
    components: {
        AppHeader,
    },

    data: () => ({
        items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
        modal: false,
        menu2: false,
        menu3: false,
    }),
    mounted()
    {

    },

    methods: {
        membershipRewardPoints() // xml request payload to soap-services
        {
            let obj = {
                idSeed: sharedServices.getIdSeed(),
                sysId: new DeviceUUID().get(),
                messageStamp: moment(new Date()).format('DD/MM/yyyy HH:mm:ss'),
                sysAuth: store.get('requestKeys/accessToken'),
                correlationID: Math.floor(1000 + Math.random() * 9000).toString(),
                requestorID: localStorage.getItem('userName'),
                // loyaltyAccountNumber: this.primaryCards[index].loyaltyAccountNbr
            }

            soapServices.soapAPI(obj).then(soapResp =>
            {
                this.membershipRewards =
                    soapResp.GetSummaryMRResponse.GetSummaryMRResult.Resp.Body.getSummaryResponseType.summaryType.availableBalance
            })
        },
    }

}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';
</style>
